import Close from '_core/assets/icons/close.svg';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

const Icon = ({ type, title, size, color, className }) => {
  const [iconSrc, setIconSrc] = useState(Close);
  const [libComponent, setLibComponent] = useState('core');

  useEffect(() => {
    if (type) {
      import(`app/assets/icons/${type}.svg`)
        .then((icon) => {
          setIconSrc(icon.default);
          setLibComponent('skin');
        })
        .catch(() => {
          import(`_core/assets/icons/${type}.svg`)
            .then((icon) => {
              setIconSrc(icon.default);
              setLibComponent('core');
            })
            .catch(() => {
              setIconSrc(Close);
            });
        });
    }
  }, [type]);

  return (
    <ReactSVG
      src={iconSrc}
      title={title}
      className="icon__wrapper"
      beforeInjection={(svg) => {
        svg.classList.add('icon');
        svg.classList.add(`icon--lib-${libComponent}`);
        if (size) svg.classList.add(`icon--size-${size}`);
        if (color) svg.classList.add(`icon--color-${color}`);
      }}
    />
  );
};

Icon.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.oneOf(['xs', 's', 'default', 'big', 'big-2', 'xl', 'xxl']),
  color: PropTypes.oneOf([
    '',
    ' ',
    'default',
    'gradient',
    'primary',
    'secondary',
    'white',
    'blue',
    'blue2',
    'black',
    'light',
    'success',
    'red',
    'yellow',
    'label',
    'green',
    'brown',
    'original',
  ]),
};

Icon.defaultProps = {
  type: 'search',
  title: '',
  size: 'default',
  color: 'default',
};

export default Icon;
